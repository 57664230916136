import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import FormPage from "app/templates/FormPage";

const RequestDemoPage = () => {
  const data = useStaticQuery(graphql`
    {
      entry: contentJson(templateId: { eq: "form" }) {
        seo {
          ...SEO
        }
        pardotUrl
      }
    }
  `);

  return <FormPage data={data} />;
};

export default RequestDemoPage;
