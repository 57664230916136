import React from "react";
import { css } from "@emotion/react";
import { StaticImage } from "gatsby-plugin-image";
import _get from "lodash.get";

import PardotForm from "app/components/pardot-form";

import Layout from "app/components/layout";
import SEOBlock, { seoFields } from "app/components/seo";

import * as MarkdownStyles from "app/components/markdown-block.styles";
import * as Styles from "app/styles/shared";

const S = {
  ...MarkdownStyles,
  ...Styles,
};

const heroMast = css`
  height: 350px;
`;

const FormPage = ({ data }) => {
  return (
    <Layout>
      <SEOBlock {..._get(data, "entry.seo")} titleTemplate="%s | TED@Work" />
      <div css={[S.bgBlack, S.relative, heroMast]} id="page-header">
        <StaticImage
          src={"../images/ted-at-work-form-header.jpg"}
          css={S.cover}
          alt=""
        />
      </div>
      <PardotForm
        formClass="mt-[-10rem] !max-w-[600px] px-5 pt-5"
        pardotUrl={_get(data, "entry.pardotUrl")}
        formHeight={1540}
        eventName="request-demo-form-submit"
      />
    </Layout>
  );
};

export default FormPage;

export const formFields = [
  seoFields,
  {
    label: "Form Url",
    name: "pardotUrl",
    widget: "string",
  },
];
